import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, useTheme, useMediaQuery, Divider, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import Checkbox from '@mui/material/Checkbox';
import {Routes, Route, useNavigate} from 'react-router-dom';
import KenTextField from '../../Components/KenTextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



const useStyles = makeStyles((theme) => ({
    Title: {
        textAlign: 'left',
        color: '#092682',
        fontSize: '20px',
        fontWeight: 700,
        paddingTop: 3
    },
    secondTitle: {
        textAlign: 'left',
        color: '#061938',
        fontSize: '16px',
        fontWeight: 400,
        paddingTop: '20px'
    },
    PaperContainer: {
        display: '-webkit-inline-box',
        padding: '32px',
        width: '90%',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100%',
            padding:'32px 0px 0px 5px '
        }
    },
    FormDetailleft: {
        textAlign: 'left'
    },
    FormData: {
        width: '200px',
        color: '#7A869A',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {

        }
    },
   
    FormResData: {
        width: '200px',
        color: '#061938',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100px'
        }
    },
    FormDataS: {
        color: '#7A869A',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {

        }
    },
    FormResDataS: {
        color: '#061938',
        
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: 'none'
        }
    },
    PersonalAddress: {
        width: '100px',
        color: '#7A869A',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '80px'
        }
    },
    DeclerationSub:{
        fontWeight: 400,
        color:'#061938',
        textAlign:'left',
        fontSize:'16px'

    },
    DeclerationNam:{
        fontWeight: 600,
        color: '#092682',
        fontSize:'16px'
    },
    DeclerationContent:{
        fontWeight: 400,
        color: '#7A869A',
        fontSize:'14px',
        textAlign:'left',
        lineHeight:'300%'
    },
    DeclerationFooter:{
        fontWeight: 400,
        color: '#7A869A',
        fontSize:'14px',
        textAlign:'left',
        lineHeight:'200%'
    },
    button: {
        fontSize:'18px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        border:'2px solid white',
        textTransform: 'capitalize',   
        fontWeight:400, 
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            fontSize:'12px',
        }
    },
    button2: {
        fontSize:'18px',
        'border-radius': '4px',
        'color': '#092682',
        backgroundColor:'white',
        border:'2px solid white',
        textTransform: 'capitalize',
        fontWeight:400,     
        '&:hover': {
            color: 'white'
        },
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            fontSize:'12px',
        }
    },
    PaperFooter:{
        display: '-webkit-inline-box',
        backgroundColor:'#092682',
        padding:'10px',
        width: '90%',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100%'
        }  
    }
}))

export default function PaymentSummary() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(
        theme.breakpoints.down(TABLET_BREAKPOINT)
    );
    const navigate = useNavigate();
    const navigateToContacts = () => {
        navigate('/proceedToPayment');
      };
    return (
        <React.Fragment>
            <div >
                <Paper elevation={3} className={classes.PaperContainer}>
                    <Box style={{width:'100%'}}>
                        <Typography className={classes.Title}>Summary</Typography>
                        <Typography className={classes.secondTitle}>Programme Details</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'}>
                            <Grid container spacing={isMobileScreen ? 0 : 15} >
                                <Grid item md={6} sm={12} xs={12} lg={6}>
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Preferred course -1</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>B.tech</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container  >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Academic session</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>2022</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Duration</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>4 yrs</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mode of Education</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Offline</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Preferred course -1</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>BBA</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container  >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Academic session</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>2022</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Duration</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>3 yrs</Typography>
                                        </Grid>
                                    </Grid> <br />
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mode of Education</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Online</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box> <br />
                        <Divider />
                        <Typography className={classes.secondTitle}>Personal Information</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft} >
                                            <Typography className={classes.FormData}>Salution</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Master</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Suresh</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>suresh@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Alternate Email</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>suresh001@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>98999467738</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Alternate Mobile</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9899467836</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>DOB</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>16/12/1999</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Marital Status</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Single</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Religion</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Hindu</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Cast Category</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>General</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Annual Family Income</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>0000000</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mother Tongue</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Tamil</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Second Language</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>English</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Hostel Facility</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Yes</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Nationality</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Indian</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 3 : 15}>
                                <Grid item className={classes.FormDetailleft} >
                                    <Typography className={classes.FormDataS}>How did you hear about us?</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography className={classes.FormResDataS}>Through word of mouth</Typography>
                                </Grid>
                            </Grid> <br />
                        </Box> 
                        <Divider />
                        <Typography className={classes.secondTitle}>Personal Address </Typography>

                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.PersonalAddress}>Country</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>India</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 3 : 15}>
                                <Grid item className={classes.FormDetailleft} >
                                    <Typography className={classes.PersonalAddress}>Address 1</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>5/10 street,Vatica city,sohna road,sector-49,Opposite to mall</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 3 : 15}>
                                <Grid item className={classes.FormDetailleft} >
                                    <Typography className={classes.PersonalAddress}>Address 2</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>5/10 street,Vatica city,sohna road,sector-49,Opposite to mall</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container >
                                <Grid item  spacing={isMobileScreen ? 3 : 14} md={6} sm={6} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft} md={6} sm={6} xs={12}>
                                            <Typography className={classes.FormData}>Distric</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Kormangla</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item spacing={isMobileScreen ? 3 : 15} md={6} sm={6} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Pin Code</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>500123</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 5}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.FormDataS}>Permanent Address same as correspondence?</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>Yes</Typography>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />

                        <Typography className={classes.secondTitle}>Parents’/Guardians’ Details</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Rakesh Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Relation with Applicant</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Son</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>rakeshsharma@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile No.</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9800896756</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Occupation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Business Consultant</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Typography className={classes.secondTitle}>Parents’/Guardians’ Address </Typography>

                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.PersonalAddress}>Country</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>India</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 3 : 15}>
                                <Grid item className={classes.FormDetailleft} >
                                    <Typography className={classes.PersonalAddress}>Address 1</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>5/10 street,Vatica city,sohna road,sector-49,Opposite to mall</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 3 : 15}>
                                <Grid item className={classes.FormDetailleft} >
                                    <Typography className={classes.PersonalAddress}>Address 2</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>5/10 street,Vatica city,sohna road,sector-49,Opposite to mall</Typography>
                                </Grid>
                            </Grid> <br />
                            <Grid container >
                            <Grid item  spacing={isMobileScreen ? 3 : 14} md={6} sm={6} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft} md={6} sm={6} xs={12}>
                                            <Typography className={classes.FormData}>Distric</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Kormangla</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item spacing={isMobileScreen ? 0 : 15}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Pin Code</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>500123</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 5}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.FormDataS}>Permanent Address same as correspondence?</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>Yes</Typography>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Typography className={classes.secondTitle}>Education Details</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <div className={classes.FormDetailleft}>
                                <Typography className={classes.FormData}>SSC/ 10th/ SSLC Details</Typography> <br />
                            </div>
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Rakesh Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Relation with Applicant</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Son</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>rakeshsharma@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile No.</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9800896756</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Occupation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Business Consultant</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <div className={classes.FormDetailleft}>
                                <Typography className={classes.FormData}>HSC/ 12th/ Diploma</Typography>
                            </div>
                            <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Rakesh Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Relation with Applicant</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Son</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>rakeshsharma@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile No.</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9800896756</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Occupation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Business Consultant</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <div className={classes.FormDetailleft}>
                                <Typography className={classes.FormData}>Graduation</Typography> <br />
                            </div>
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Rakesh Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Relation with Applicant</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Son</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>rakeshsharma@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile No.</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9800896756</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Occupation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Business Consultant</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Honours Subject Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Communication Design</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Exam Type</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Enterance Exam</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <div className={classes.FormDetailleft}>
                                <Typography className={classes.FormData}>Post Graduation</Typography> <br />
                            </div>
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>First Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Rakesh Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Last Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Sharma</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Relation with Applicant</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Son</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Email Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>rakeshsharma@gmail.com</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Mobile No.</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>9800896756</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Occupation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Business Consultant</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Honours Subject Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Communication Design</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Typography className={classes.secondTitle}>Work Experience</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Lastest Org. Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>M Group</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Designation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.FormResData}>Intern</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item md={6} sm={12} xs={12} >
                                    <Grid container >

                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Dates</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>April -July 2022</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Typography className={classes.secondTitle}>Competitive Exam Details</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.FormDataS}>Have you taken competitve exmas?</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>Yes</Typography>
                                </Grid>
                            </Grid> <br />
                        </Box>
                        <Divider />
                        <Typography className={classes.secondTitle}>Referral Code(if any)</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 60px'} >
                            <Grid container spacing={isMobileScreen ? 0 : 15}>
                                <Grid item className={classes.FormDetailleft}>
                                    <Typography className={classes.FormDataS}>Referral Code</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.FormResDataS}>12378-12346</Typography>
                                </Grid>
                            </Grid> <br />
                        </Box>
                    </Box>
                </Paper><br /><br />
                <Paper elevation={3} className={classes.PaperContainer}>
                    <Box padding={1} width={'100%'}>
                    
                        <Typography className={classes.Title}>Document Details</Typography>
                        <Typography className={classes.secondTitle}>Student Documents</Typography>

                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 30px'} >
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="15px" height="15px" ></img>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                            <Typography className={classes.FormDataS}>Passport Size Photo</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={4}>
                            <Typography className={classes.FormResDataS}>Photo.jpg</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAhFBMVEX///8mJv8AAP8bG/8gIP8QEP/7+/8UFP8jI/8GBv8YGP9YWP9HR/+Li//Z2f/x8f9wcP/o6P/Pz/+5uf9/f/+Ojv+srP9CQv95ef+Hh/+bm//U1P+pqf+YmP9oaP9NTf9gYP/j4/++vv82Nv/29v+ysv/Dw/9SUv+kpP8tLf+Tk//Kyv9CRxQTAAAET0lEQVRoge1a2XbjIAwlYIzBiZdsdprNbvbk//9vkJy0mXbATtOWh+E+zGk7govElSyDCfHw8PDw8PDw8PDw+CmEeTIcLZazscZsuRgNkzz8Dd5tvl+u1htOKeVcaHD8cbNeLff59ieZ09F4zSlnQdT7gChg+j/W41H6M8z5MaZUBPeEiLuFBILS+Jh/O/O0oDy4kQqJoT7EVRUfcAukuC0i4LSYfif/MFNX5ohRuqlmx0u9Da8aC8NtfTnOqg2lLLryq2z4PczbUczFdVJZrKaJYVvTZLoq5HWRgsej5+UXLgrK0GcuivOwRU/p8FwIjv4zHf0n82+3oUEzFZ/Muw2ZT3iz3IBudk9Qv54oeBEJuto/Mm6/ogIH0tPrF6nrNXodSTWrHx2bz5SM0Pv1w2M10oFgSH06fylx8vMJ6ZkYPFx49oVE1QaTL+dsPgkwS2Tx0J6RsMSIB7L8StDeUJcSp6HlA7pPehyWTPt2gefzy2VuD8y8T2Em3ku6ci9R44JbEqXeZbrWNWDZzhKfHVaoiC47UeeVROvM6JOueUyqtwdKpCSz1LM8Q19k1UE8ww2sVJxGJoNw12+KyD0Y7e+M+zo64ZSb1nr/ikqTsTGQyaGpeZHiXDEG/0ZNPTsY97WOJVq0VJwXiiGfGA0WjdeKBtliP6/r+X6RBVQ13i+MwyYYevpi4y6Bm/GL0SBrxEsHf3mZDGiTHplx4IXDomlpNAgrmEKujcoIYzBQavKpZqUTBd7z2Ljx+RpCzyuDQTM1zczVMAbh0Pife5vEEBQRGwenGDXD8q7cM+NokqHBwLT2AU5ujjyZGdlDTG+L1LTWWjSDarWoTssOE/4z+wq5j+aRSRv3jd1SSY/Ivvr45xK5LQU1PDCIuY2bEIg8O1ieIjtk/6D5M3JPLfPCMGUW83WFsbK7QKbIfr7/02trSLd9XddU67Mp0eRB39a3NltzV+tqqD98bJsVlsctarxhwv+e+jPGHGroe/nuM2uGAirY8Q79UAq7XllNoFqw/u03lElh7fFrza0sJeAdMx14Zu1/tsWddOcQB2bfzh2359A7ICNtbQiYwGsVb7okzCGb0DVKodfXhZsQPbMwPz8QU8xI+GkkW3eJkF7UU5bCeY9MaeMWG1CQhHal0DlE21ociKWlbt5jATvUYpPr+YJC77h2XLQULjTmHbvvPe/gzEArXs7Ji7aVbTkEKlJdXxRVB22m2mX+QkrWY/YU1xiCQjq23gnot7VXjDVt6ZbcadidCs5pqrktMk7Lq9sHi9NHqttmwm0b5bSBdNs6u31pcPu65PZF0ekrstvDAbfHIsTpgRBxexTm9hDQ7fGn24Nf4vTImzxw2D/8/sN+t9cc5P2Ch/3+BQ9xe7VFnrvUq5+71AM4vM4EOLzI1Qin7q6widPLe4S7zxYQDj/YaPidfarSwN1HOg3cfZ50g6sPszw8PDw8PDw8PDz+U/wBKH1dzRpJQIgAAAAASUVORK5CYII=' width="15px" height="15px" ></img>
                            </Grid>
                          </Grid>
                        </Box>
                        
                        
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 30px'}>
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="15px" height="15px" ></img>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                            <Typography className={classes.FormDataS}>Birth Certificate</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={4}>
                            <Typography className={classes.FormResDataS}>Certificate.pdf</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAhFBMVEX///8mJv8AAP8bG/8gIP8QEP/7+/8UFP8jI/8GBv8YGP9YWP9HR/+Li//Z2f/x8f9wcP/o6P/Pz/+5uf9/f/+Ojv+srP9CQv95ef+Hh/+bm//U1P+pqf+YmP9oaP9NTf9gYP/j4/++vv82Nv/29v+ysv/Dw/9SUv+kpP8tLf+Tk//Kyv9CRxQTAAAET0lEQVRoge1a2XbjIAwlYIzBiZdsdprNbvbk//9vkJy0mXbATtOWh+E+zGk7govElSyDCfHw8PDw8PDw8PDw+CmEeTIcLZazscZsuRgNkzz8Dd5tvl+u1htOKeVcaHD8cbNeLff59ieZ09F4zSlnQdT7gChg+j/W41H6M8z5MaZUBPeEiLuFBILS+Jh/O/O0oDy4kQqJoT7EVRUfcAukuC0i4LSYfif/MFNX5ohRuqlmx0u9Da8aC8NtfTnOqg2lLLryq2z4PczbUczFdVJZrKaJYVvTZLoq5HWRgsej5+UXLgrK0GcuivOwRU/p8FwIjv4zHf0n82+3oUEzFZ/Muw2ZT3iz3IBudk9Qv54oeBEJuto/Mm6/ogIH0tPrF6nrNXodSTWrHx2bz5SM0Pv1w2M10oFgSH06fylx8vMJ6ZkYPFx49oVE1QaTL+dsPgkwS2Tx0J6RsMSIB7L8StDeUJcSp6HlA7pPehyWTPt2gefzy2VuD8y8T2Em3ku6ci9R44JbEqXeZbrWNWDZzhKfHVaoiC47UeeVROvM6JOueUyqtwdKpCSz1LM8Q19k1UE8ww2sVJxGJoNw12+KyD0Y7e+M+zo64ZSb1nr/ikqTsTGQyaGpeZHiXDEG/0ZNPTsY97WOJVq0VJwXiiGfGA0WjdeKBtliP6/r+X6RBVQ13i+MwyYYevpi4y6Bm/GL0SBrxEsHf3mZDGiTHplx4IXDomlpNAgrmEKujcoIYzBQavKpZqUTBd7z2Ljx+RpCzyuDQTM1zczVMAbh0Pife5vEEBQRGwenGDXD8q7cM+NokqHBwLT2AU5ujjyZGdlDTG+L1LTWWjSDarWoTssOE/4z+wq5j+aRSRv3jd1SSY/Ivvr45xK5LQU1PDCIuY2bEIg8O1ieIjtk/6D5M3JPLfPCMGUW83WFsbK7QKbIfr7/02trSLd9XddU67Mp0eRB39a3NltzV+tqqD98bJsVlsctarxhwv+e+jPGHGroe/nuM2uGAirY8Q79UAq7XllNoFqw/u03lElh7fFrza0sJeAdMx14Zu1/tsWddOcQB2bfzh2359A7ICNtbQiYwGsVb7okzCGb0DVKodfXhZsQPbMwPz8QU8xI+GkkW3eJkF7UU5bCeY9MaeMWG1CQhHal0DlE21ociKWlbt5jATvUYpPr+YJC77h2XLQULjTmHbvvPe/gzEArXs7Ji7aVbTkEKlJdXxRVB22m2mX+QkrWY/YU1xiCQjq23gnot7VXjDVt6ZbcadidCs5pqrktMk7Lq9sHi9NHqttmwm0b5bSBdNs6u31pcPu65PZF0ekrstvDAbfHIsTpgRBxexTm9hDQ7fGn24Nf4vTImzxw2D/8/sN+t9cc5P2Ch/3+BQ9xe7VFnrvUq5+71AM4vM4EOLzI1Qin7q6widPLe4S7zxYQDj/YaPidfarSwN1HOg3cfZ50g6sPszw8PDw8PDw8PDz+U/wBKH1dzRpJQIgAAAAASUVORK5CYII=' width="15px" height="15px" ></img>
                            </Grid>
                          </Grid>
                          
                        </Box>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 30px'} >
                            
                        
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="15px" height="15px" ></img>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                            <Typography className={classes.FormDataS}>Academic Records</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={4}>
                            <Typography className={classes.FormResDataS}>Record.pdf</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAhFBMVEX///8mJv8AAP8bG/8gIP8QEP/7+/8UFP8jI/8GBv8YGP9YWP9HR/+Li//Z2f/x8f9wcP/o6P/Pz/+5uf9/f/+Ojv+srP9CQv95ef+Hh/+bm//U1P+pqf+YmP9oaP9NTf9gYP/j4/++vv82Nv/29v+ysv/Dw/9SUv+kpP8tLf+Tk//Kyv9CRxQTAAAET0lEQVRoge1a2XbjIAwlYIzBiZdsdprNbvbk//9vkJy0mXbATtOWh+E+zGk7govElSyDCfHw8PDw8PDw8PDw+CmEeTIcLZazscZsuRgNkzz8Dd5tvl+u1htOKeVcaHD8cbNeLff59ieZ09F4zSlnQdT7gChg+j/W41H6M8z5MaZUBPeEiLuFBILS+Jh/O/O0oDy4kQqJoT7EVRUfcAukuC0i4LSYfif/MFNX5ohRuqlmx0u9Da8aC8NtfTnOqg2lLLryq2z4PczbUczFdVJZrKaJYVvTZLoq5HWRgsej5+UXLgrK0GcuivOwRU/p8FwIjv4zHf0n82+3oUEzFZ/Muw2ZT3iz3IBudk9Qv54oeBEJuto/Mm6/ogIH0tPrF6nrNXodSTWrHx2bz5SM0Pv1w2M10oFgSH06fylx8vMJ6ZkYPFx49oVE1QaTL+dsPgkwS2Tx0J6RsMSIB7L8StDeUJcSp6HlA7pPehyWTPt2gefzy2VuD8y8T2Em3ku6ci9R44JbEqXeZbrWNWDZzhKfHVaoiC47UeeVROvM6JOueUyqtwdKpCSz1LM8Q19k1UE8ww2sVJxGJoNw12+KyD0Y7e+M+zo64ZSb1nr/ikqTsTGQyaGpeZHiXDEG/0ZNPTsY97WOJVq0VJwXiiGfGA0WjdeKBtliP6/r+X6RBVQ13i+MwyYYevpi4y6Bm/GL0SBrxEsHf3mZDGiTHplx4IXDomlpNAgrmEKujcoIYzBQavKpZqUTBd7z2Ljx+RpCzyuDQTM1zczVMAbh0Pife5vEEBQRGwenGDXD8q7cM+NokqHBwLT2AU5ujjyZGdlDTG+L1LTWWjSDarWoTssOE/4z+wq5j+aRSRv3jd1SSY/Ivvr45xK5LQU1PDCIuY2bEIg8O1ieIjtk/6D5M3JPLfPCMGUW83WFsbK7QKbIfr7/02trSLd9XddU67Mp0eRB39a3NltzV+tqqD98bJsVlsctarxhwv+e+jPGHGroe/nuM2uGAirY8Q79UAq7XllNoFqw/u03lElh7fFrza0sJeAdMx14Zu1/tsWddOcQB2bfzh2359A7ICNtbQiYwGsVb7okzCGb0DVKodfXhZsQPbMwPz8QU8xI+GkkW3eJkF7UU5bCeY9MaeMWG1CQhHal0DlE21ociKWlbt5jATvUYpPr+YJC77h2XLQULjTmHbvvPe/gzEArXs7Ji7aVbTkEKlJdXxRVB22m2mX+QkrWY/YU1xiCQjq23gnot7VXjDVt6ZbcadidCs5pqrktMk7Lq9sHi9NHqttmwm0b5bSBdNs6u31pcPu65PZF0ekrstvDAbfHIsTpgRBxexTm9hDQ7fGn24Nf4vTImzxw2D/8/sN+t9cc5P2Ch/3+BQ9xe7VFnrvUq5+71AM4vM4EOLzI1Qin7q6widPLe4S7zxYQDj/YaPidfarSwN1HOg3cfZ50g6sPszw8PDw8PDw8PDz+U/wBKH1dzRpJQIgAAAAASUVORK5CYII=' width="15px" height="15px" ></img>
                            </Grid>
                          </Grid>
                          
                        </Box>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 30px'} >

                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="15px" height="15px" ></img>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                            <Typography className={classes.FormDataS}>Signature of Student</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={4}>
                            <Typography className={classes.FormResDataS}>Sign.jpg</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAhFBMVEX///8mJv8AAP8bG/8gIP8QEP/7+/8UFP8jI/8GBv8YGP9YWP9HR/+Li//Z2f/x8f9wcP/o6P/Pz/+5uf9/f/+Ojv+srP9CQv95ef+Hh/+bm//U1P+pqf+YmP9oaP9NTf9gYP/j4/++vv82Nv/29v+ysv/Dw/9SUv+kpP8tLf+Tk//Kyv9CRxQTAAAET0lEQVRoge1a2XbjIAwlYIzBiZdsdprNbvbk//9vkJy0mXbATtOWh+E+zGk7govElSyDCfHw8PDw8PDw8PDw+CmEeTIcLZazscZsuRgNkzz8Dd5tvl+u1htOKeVcaHD8cbNeLff59ieZ09F4zSlnQdT7gChg+j/W41H6M8z5MaZUBPeEiLuFBILS+Jh/O/O0oDy4kQqJoT7EVRUfcAukuC0i4LSYfif/MFNX5ohRuqlmx0u9Da8aC8NtfTnOqg2lLLryq2z4PczbUczFdVJZrKaJYVvTZLoq5HWRgsej5+UXLgrK0GcuivOwRU/p8FwIjv4zHf0n82+3oUEzFZ/Muw2ZT3iz3IBudk9Qv54oeBEJuto/Mm6/ogIH0tPrF6nrNXodSTWrHx2bz5SM0Pv1w2M10oFgSH06fylx8vMJ6ZkYPFx49oVE1QaTL+dsPgkwS2Tx0J6RsMSIB7L8StDeUJcSp6HlA7pPehyWTPt2gefzy2VuD8y8T2Em3ku6ci9R44JbEqXeZbrWNWDZzhKfHVaoiC47UeeVROvM6JOueUyqtwdKpCSz1LM8Q19k1UE8ww2sVJxGJoNw12+KyD0Y7e+M+zo64ZSb1nr/ikqTsTGQyaGpeZHiXDEG/0ZNPTsY97WOJVq0VJwXiiGfGA0WjdeKBtliP6/r+X6RBVQ13i+MwyYYevpi4y6Bm/GL0SBrxEsHf3mZDGiTHplx4IXDomlpNAgrmEKujcoIYzBQavKpZqUTBd7z2Ljx+RpCzyuDQTM1zczVMAbh0Pife5vEEBQRGwenGDXD8q7cM+NokqHBwLT2AU5ujjyZGdlDTG+L1LTWWjSDarWoTssOE/4z+wq5j+aRSRv3jd1SSY/Ivvr45xK5LQU1PDCIuY2bEIg8O1ieIjtk/6D5M3JPLfPCMGUW83WFsbK7QKbIfr7/02trSLd9XddU67Mp0eRB39a3NltzV+tqqD98bJsVlsctarxhwv+e+jPGHGroe/nuM2uGAirY8Q79UAq7XllNoFqw/u03lElh7fFrza0sJeAdMx14Zu1/tsWddOcQB2bfzh2359A7ICNtbQiYwGsVb7okzCGb0DVKodfXhZsQPbMwPz8QU8xI+GkkW3eJkF7UU5bCeY9MaeMWG1CQhHal0DlE21ociKWlbt5jATvUYpPr+YJC77h2XLQULjTmHbvvPe/gzEArXs7Ji7aVbTkEKlJdXxRVB22m2mX+QkrWY/YU1xiCQjq23gnot7VXjDVt6ZbcadidCs5pqrktMk7Lq9sHi9NHqttmwm0b5bSBdNs6u31pcPu65PZF0ekrstvDAbfHIsTpgRBxexTm9hDQ7fGn24Nf4vTImzxw2D/8/sN+t9cc5P2Ch/3+BQ9xe7VFnrvUq5+71AM4vM4EOLzI1Qin7q6widPLe4S7zxYQDj/YaPidfarSwN1HOg3cfZ50g6sPszw8PDw8PDw8PDz+U/wBKH1dzRpJQIgAAAAASUVORK5CYII=' width="15px" height="15px" ></img>
                            </Grid>
                          </Grid>
                          
                        </Box> <br />
                        <Divider/>
                        <Typography className={classes.secondTitle}>Student Documents</Typography>
                        <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 0px 0px 30px'} >
                        
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} sm={1} xs={1} >
                            <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="15px" height="15px" ></img>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                            <Typography className={classes.FormDataS}>Adhaar/PAN/Passport</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={4}>
                            <Typography className={classes.FormResDataS}>PAN.pdf</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAhFBMVEX///8mJv8AAP8bG/8gIP8QEP/7+/8UFP8jI/8GBv8YGP9YWP9HR/+Li//Z2f/x8f9wcP/o6P/Pz/+5uf9/f/+Ojv+srP9CQv95ef+Hh/+bm//U1P+pqf+YmP9oaP9NTf9gYP/j4/++vv82Nv/29v+ysv/Dw/9SUv+kpP8tLf+Tk//Kyv9CRxQTAAAET0lEQVRoge1a2XbjIAwlYIzBiZdsdprNbvbk//9vkJy0mXbATtOWh+E+zGk7govElSyDCfHw8PDw8PDw8PDw+CmEeTIcLZazscZsuRgNkzz8Dd5tvl+u1htOKeVcaHD8cbNeLff59ieZ09F4zSlnQdT7gChg+j/W41H6M8z5MaZUBPeEiLuFBILS+Jh/O/O0oDy4kQqJoT7EVRUfcAukuC0i4LSYfif/MFNX5ohRuqlmx0u9Da8aC8NtfTnOqg2lLLryq2z4PczbUczFdVJZrKaJYVvTZLoq5HWRgsej5+UXLgrK0GcuivOwRU/p8FwIjv4zHf0n82+3oUEzFZ/Muw2ZT3iz3IBudk9Qv54oeBEJuto/Mm6/ogIH0tPrF6nrNXodSTWrHx2bz5SM0Pv1w2M10oFgSH06fylx8vMJ6ZkYPFx49oVE1QaTL+dsPgkwS2Tx0J6RsMSIB7L8StDeUJcSp6HlA7pPehyWTPt2gefzy2VuD8y8T2Em3ku6ci9R44JbEqXeZbrWNWDZzhKfHVaoiC47UeeVROvM6JOueUyqtwdKpCSz1LM8Q19k1UE8ww2sVJxGJoNw12+KyD0Y7e+M+zo64ZSb1nr/ikqTsTGQyaGpeZHiXDEG/0ZNPTsY97WOJVq0VJwXiiGfGA0WjdeKBtliP6/r+X6RBVQ13i+MwyYYevpi4y6Bm/GL0SBrxEsHf3mZDGiTHplx4IXDomlpNAgrmEKujcoIYzBQavKpZqUTBd7z2Ljx+RpCzyuDQTM1zczVMAbh0Pife5vEEBQRGwenGDXD8q7cM+NokqHBwLT2AU5ujjyZGdlDTG+L1LTWWjSDarWoTssOE/4z+wq5j+aRSRv3jd1SSY/Ivvr45xK5LQU1PDCIuY2bEIg8O1ieIjtk/6D5M3JPLfPCMGUW83WFsbK7QKbIfr7/02trSLd9XddU67Mp0eRB39a3NltzV+tqqD98bJsVlsctarxhwv+e+jPGHGroe/nuM2uGAirY8Q79UAq7XllNoFqw/u03lElh7fFrza0sJeAdMx14Zu1/tsWddOcQB2bfzh2359A7ICNtbQiYwGsVb7okzCGb0DVKodfXhZsQPbMwPz8QU8xI+GkkW3eJkF7UU5bCeY9MaeMWG1CQhHal0DlE21ociKWlbt5jATvUYpPr+YJC77h2XLQULjTmHbvvPe/gzEArXs7Ji7aVbTkEKlJdXxRVB22m2mX+QkrWY/YU1xiCQjq23gnot7VXjDVt6ZbcadidCs5pqrktMk7Lq9sHi9NHqttmwm0b5bSBdNs6u31pcPu65PZF0ekrstvDAbfHIsTpgRBxexTm9hDQ7fGn24Nf4vTImzxw2D/8/sN+t9cc5P2Ch/3+BQ9xe7VFnrvUq5+71AM4vM4EOLzI1Qin7q6widPLe4S7zxYQDj/YaPidfarSwN1HOg3cfZ50g6sPszw8PDw8PDw8PDz+U/wBKH1dzRpJQIgAAAAASUVORK5CYII=' width="15px" height="15px" ></img>
                            </Grid>
                          </Grid>
                          <br />
                        </Box>
                    </Box>
                </Paper> <br /> <br />
                <Paper elevation={3} className={classes.PaperContainer}>
                <Box>
                    <Typography className={classes.Title}>Declaration</Typography>
                    <Box padding={isMobileScreen ? '30px 0px 0px 0px' : '30px 60px 0px 80px'} >
                        <Typography className={classes.DeclerationSub}><span className={classes.DeclerationNam}>Suresh Sharma</span > S/O <span className={classes.DeclerationNam}>Rakesh Sharma</span> do hereby undertake this on Tuesday, January 4th 2022, the following:</Typography>    
                        <br/>
                        <Typography className={classes.DeclerationContent}>1. I hereby declare that the entries filled by me in this application form is correct to the best of my knowledge.</Typography>
                        <Typography className={classes.DeclerationContent}>2. I understand that the decision of the Bright Kid is final in regard to the selection for admission.</Typography>
                        <Typography className={classes.DeclerationContent}>3. I promise to abide by the rules and regulations of the institute and the guidelines as specified by the institute.</Typography>
                        <Typography className={classes.DeclerationContent}>4. The institute shall have the right to expel me from it at any time after admission for false information furnished by me or Code of Conduct or on my antecedent's proof that my continuation in this institute is undesirable.</Typography>
                        <Typography className={classes.DeclerationContent}>5. I agree that all disputes are subject to the jurisdiction of the courts at the City</Typography>
                        <br />
                        <div style={{display:'flex'}}>
                            <div style={{marginLeft:'-12px'}}>
                        <Checkbox />    
                           </div> 
                        <Typography sx={{color:'#092682',marginTop:'9px',textAlign:'left'}}>I Agree</Typography>
                        </div>
                    </Box>
                    <Divider/>              
                    <Typography className={classes.DeclerationFooter}>Review the above paticulars carefully.If you would like to change any paticulars entered, you may do so by pressing Previous button or press ‘Next’ button for final submission</Typography>
                </Box>
                </Paper> <br /> <br />
                <Paper elevation={3} className={classes.PaperFooter}  >
                 <Grid container spacing={2} justifyContent='end'>
                    <Grid item >
                    <Button className={classes.button} >Suggest Changes to Partner</Button>
                    </Grid>
                    <Grid item>
                    <Button onClick={navigateToContacts} className={classes.button2} >Proceed to Paymnet</Button>
                    </Grid>
                 </Grid>
                </Paper>
            </div>
        </React.Fragment>
    )
}
