import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SideNavigation from '../../Components/SideNavigation';
// import HomePage from '../HomePage';
import MenuAppBar from '../../Components/MenuAppBar';
// import DocumentPage from '../DocumentPage';
import { AuthContext } from '../../Context/authContext';
import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp';
import routes from '../../Constants/routes';
import KenPrivateRoute from '../../Components/KenPrivateRoute';
import LoginOTP from '../Auth/Login/LoginOTP';
import VerifyNumber from '../Auth/SignUp/VerifyNumber';
import VerifyEmail from '../Auth/SignUp/VerifyEmail';
import VerifiedOTP from '../Auth/SignUp/VerifiedOTP';
// import Courses from '../Courses';
// import ApplicationForm from '../ApplicationForm';
// import MyApplications from '../MyApplications';
// import FeePayment from '../FeePayment';
import KenLoader from '../../Components/KenLoader';
import { useAppContext } from '../../Context/appContext';
import { getAxiosInstance, getConfig } from '../../Utils/apiServices';
import { useTheme } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../Utils/ErrorBoundary';
// import { useIdleTimer } from 'react-idle-timer';
// import FeeStructure from '../FeePayment/Components/FeeStructure';
// import FeeDetails from '../FeePayment/Components/FeeDetails';
import {
  PERMISSIONS,
  ROLES,
  TABLET_BREAKPOINT,
} from '../../Constants/constant';
// import ApplicationFormNew from '../ApplicationForm/Application';
// import OfferLetter from '../OfferLetter';
// import OfferLetterDetailed from '../OfferLetter/Components/OfferLetterDetailed';
// import ThankYouPage from '../../Components/KenApplicationForm/Components/ThankYouPage';
// import OnboardQues from '../OfferLetter/Components/OnboardQues';
// import InterviewSlots from '../Interview';
// import Interview from '../Interview/Interview';
import Pending from '../AnotherHome/pending';
import Applications from '../AnotherHome/Applications'
import AnotherHome from '../AnotherHome/AnotherHome';
import ProfileDetails from '../profile/ProfileDetails';
import Payment from '../Payment/Payment';
import ProceedToPayment from '../Payment Summary/ProceedToPayment';
import PaymentSummary from '../Payment Summary/PaymentSummary';


export default function MainLayout(props) {
  const [open, setOpen] = React.useState(true);
  const {
    dispatch: dispatchApp,
    state: { isAuthenticated, user },
  } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const {
    state: { isLoading = false },
    dispatch,
  } = useAppContext();

  const [config, setConfig] = useState();
  const location = useLocation();
  const [swipeableDrawer, setSwipeableDrawer] = useState();
  const isForm =
    location.pathname === '/form' || location.pathname === '/application';

  const [idle, setIdle] = useState(false);
  const handleOnIdle = () => {
    setIdle(true);
  };
  const timeout = 1000 * 60 * 10;

  // const methods = useIdleTimer({
  //   timeout,
  //   onIdle: handleOnIdle,
  // });

  useEffect(() => {
    if (idle === true) {
      dispatchApp({
        type: 'LOGOUT',
        payload: {
          user: {},
          token: null,
        },
      });
    }
  }, [idle]);

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0]?.config);
      })
      .catch((err) => {
        console.log('error in config', err);
      });
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={!isForm ? { height: '100%' } : {}}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
            window.location.reload();
          }}
        >
          <SnackbarProvider autoHideDuration={5000}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <CssBaseline />
              {!config ? (
                <KenLoader />
              ) : (
                <>
                  {isLoading && <KenLoader />}
                  {isAuthenticated && !isForm && (
                    <>
                      <SideNavigation
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                        swipeableDrawer={swipeableDrawer}
                        setOpen={setOpen}
                      />
                      <MenuAppBar
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                      />
                    </>
                  )}
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      margin: isMobileScreen
                        ? '0px'
                        : !isAuthenticated || isForm
                        ? '0px'
                        : screenSize
                        ? '80px - 28px'
                        : '80px 16px',
                      height: !isAuthenticated ? '100%' : 'calc(100% - 160px)',
                      width: isMobileScreen ? '100%' : undefined,
                      paddingTop: isMobileScreen ? '65px' : undefined,
                      //  Note: added following so for main screen the gird is splitted on 12 grid after subtracting side navbar
                      maxWidth:
                        !isMobileScreen && isAuthenticated && !isForm
                          ? open
                            ? 'calc(100% - 260px)'
                            : 'calc(100% - 145px)'
                          : undefined,
                    }}
                  >
                    <Routes>
                      {/* <Route
                        path={'/form'}
                        element={
                          isAuthenticated ? (
                            <ApplicationForm />
                          ) : (
                            <Navigate to="/AnotherHome" />
                          )
                        }
                      /> */}
                      {/* <Route
                        path={'/application'}
                        element={
                          isAuthenticated ? (
                            <ApplicationFormNew />
                          ) : (
                            <Navigate to="/AnotherHome" />
                          )
                        }
                      /> */}
                      <Route path={'/'} element={<Navigate to="/home" />} />
                      <Route
                        path={routes.login}
                        element={
                          isAuthenticated ? <Navigate to="/AnotherHome" /> : <Login />
                        }
                      />
                      <Route
                        path={routes.signup}
                        element={
                          isAuthenticated ? <Navigate to="/AnotherHome" /> : <SignUp />
                        }
                      />
                      <Route
                        path={routes.loginOTP}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/AnotherHome" />
                          ) : (
                            <LoginOTP />
                          )
                        }
                      />
                      {/* <Route
                        exact
                        path={`/feeStructure`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEESTRUCTURE}
                          >
                            <FeeStructure />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/offerLetter`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_OFFERLETTER}
                          >
                            <OfferLetter />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/interviewSlots`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_INTERVIEWSLOTS}
                          >
                            <InterviewSlots />
                          </KenPrivateRoute>
                        }
                      /> */}
                       <Route
                        exact
                        path={`/Applications`}
                        redirectTo="/login"
                        element={
                            <Applications />
                        }
                      />
                       <Route
                        exact
                        path={'/pending'}
                        redirectTo="/login"
                        element={
                            <Pending />
                        }
                      />
                      <Route
                        exact
                        path={'/profileDetails'}
                        element={
                         
                            <ProfileDetails />
                          
                        }
                      />
                      <Route
                        exact
                        path={'/payment'}
                        element={
                          
                            <Payment />
                          
                        }
                      />
                      
                      <Route
                        exact
                        path={'/anotherHome'}
                       
                        element={
                            <AnotherHome />
                        }
                      />
                      <Route
                        exact
                        path={'/proceedToPayment'}
                       
                        element={
                       
                            <ProceedToPayment />
                         
                        }
                      />
                      <Route
                        exact
                        path={'/paymentSummary'}
                       
                        element={
                       
                            <PaymentSummary />
                         
                        }
                      />
                      {/* <Route
                        exact
                        path={`/interview`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_INTERVIEW}
                          >
                            <Interview />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/offerLetterDetailed`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={
                              PERMISSIONS[ROLES.APPLICANT]
                                .APP_OFFERLETTERDETAILED
                            }
                          >
                            <OfferLetterDetailed />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/feeDetails`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEEDETAILS}
                          >
                            <FeeDetails />
                          </KenPrivateRoute>
                        }
                      /> */}
                      <Route
                        path={routes.verifyNumber}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/AnotherHome" />
                          ) : (
                            <VerifyNumber />
                          )
                        }
                      />
                      <Route
                        path={routes.verifyEmail}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/AnotherHome" />
                          ) : (
                            <VerifyEmail />
                          )
                        }
                      />
                      <Route
                        path={routes.verifiedOTP}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/AnotherHome" />
                          ) : (
                            <VerifiedOTP />
                          )
                        }
                      />
                      {/* <Route
                        path={routes.newApplication}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/AnotherHome" />
                          ) : (
                            <ApplicationForm />
                          )
                        }
                      /> */}
                      <Route
                        exact
                        path={`/home`}
                        redirectTo="/login"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_HOME}
                          >
                            <AnotherHome />
                          </KenPrivateRoute>
                        }
                      />
                      {/* <Route
                        exact
                        path={`/document`}
                        redirectTo="/login"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_DOCUMENTS}
                          >
                            <DocumentPage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/thankYou`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_THANKYOU}
                          >
                            <ThankYouPage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/feePayments`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEEPAYEMENTS}
                          >
                            <FeePayment />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`*`}
                        element={<Navigate to={routes.login} />}
                      />
                      <Route
                        exact
                        redirectTo={routes.login}
                        path={`/courses`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_COURSES}
                          >
                            <Courses />{' '}
                          </KenPrivateRoute>
                        }
                      /> */}
                      {/* APPLICANT - MY APPLICATIONS */}
                      {/* <Route
                        exact
                        // redirectTo={routes.login}
                        path={`/${routes.myApplications}`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_APPLICATIONS}
                          >
                            <MyApplications />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        redirectTo={`/${routes.home}`}
                        path={`/${routes.onboardQues}`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_ONBOARDING}
                          >
                            <OnboardQues />
                          </KenPrivateRoute>
                        }
                      /> */}
                    </Routes>
                  </Box>
                </>
              )}
            </Box>
          </SnackbarProvider>
        </ErrorBoundary>
      </div>
    </>
  );
}
