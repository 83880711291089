import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, useTheme, useMediaQuery, Divider, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import KenTextField from '../../Components/KenTextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import KenTextLabel from '../../Components/KenTextLabel';
import Checkbox from '@mui/material/Checkbox';
import KenSelect from '../../Components/KenSelect';
import { Controller, useForm } from 'react-hook-form';
import KenInput from '../../Components/KenInput';

const useStyles = makeStyles((theme) => ({
    totalContainer:{
        display: '-webkit-inline-box', 
        marginLeft: '-70px',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100%',
            marginLeft: '0px',
            
        }
    },
    Totalwidth:{
        width: '960px',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            
        }
    },
    MiddleGrid: {
        textAlign: 'left',
        borderBottom: '1px solid #B3BAC5'
    },
    ApplicantHeading: {
        fontWeight: 600,
        fontSize: '20px',
        color: '#092682',
        lineHeight: '200%'
    },
    ApplicantDescription: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '200%',
        color: '#505F79'
    },
    SpecificWord: {
        fontWeight: 600,
        color: 'grey'
    },
    AppFee: {
        fontWeight: 400,
        textAlign: 'right',
        color: '#7A869A',
        lineHeight: '200%',
        fontSize: '14px',
        border: 'none'
    },
    FeeAmount: {
        fontWeight: 600,
        fontSize: '18px',
        textAlign: 'right',
        color: '#505F79',
        lineHeight: '200%',
    },
    Ques: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '200%',
        color: '#505F79'
    },
    DiscountCode: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#092682',
        marginTop: '6px'
    },
    tableRow: {
        cursor: "pointer",
        border: "none",
        marginTop: "100px"
    },
    button: {
        width:'130px',
        marginTop: '8px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        fontSize:400,
        textTransform: 'capitalize',
        '&:hover': {
            color: '#092682'
        }
    },
    buttonModel: {
        width: '279px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#092682'
        }

    },
    model1Heading: {
        fontWeight: 600,
        textAlign: 'left',
        lineHeight: '200%',
        fontSize: '24px',
        color: '#092682',
    },
    model1sub:{
        fontWeight:'400',
        fontSize:'16',
        color:'#505F79'
    },
    ModalHead:{
        background:'#DFE8FF',
        borderRadius:'12px 12px 0px 0px'
    },
    modalheadBodyContainer:{
        padding:'20px 10px 10px 30px'
    },
    ModelAmount: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '24px',
        color: '#505F79',
        lineHeight: '100%',
    },
    ModelAmountValue: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        color: '#061938'
    },
    Model1subHeading:{
        fontWeight: 500,
        fontSize: '16px',
        color: '#092682',
        marginTop:'10px'
    },
    ReminderText: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#505F79'
    },
    sendremainder: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        color: '#061938',
        marginTop: '9px',
    },
    remaindercontent: {
        fontWeight: 400,
        fontSize: '14px',
        color: '#7A869A',

    },
    Minheight: {
        height: '250px'
    },
    Model2PayDetail: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#092682',
    },
    payDetailContent: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#505F79',
        lineHeight: '200%'
    },
    m3context: {
        fontWeight: 600,
        fontSize: '20px',
        color: '#092682',
        lineHeight: '200%'
    },
    m3Url: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#092682',
        textAlign:'center'
    },
    m3BackToHome: {
        marginTop: '200px',
        fontWeight: 400,
        fontSize: '14px',
        color: '#505F79',
    },
    FormDetailleft: {
        textAlign: 'left',
        marginTop:'10px'
    },
    FormData: {
        width: '150px',
        color: '#7A869A',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {

        }
    },
   
    FormResData: {
        width: '300px',
        color: '#061938',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100px'
        }
    },
    button: {
        fontSize:'18px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        border:'2px solid white',
        textTransform: 'capitalize', 
        fontWeight:400,   
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            fontSize:'12px',
        }
    },
    button2: {
        fontSize:'18px',
        'border-radius': '4px',
        'color': '#092682',
        backgroundColor:'white',
        border:'2px solid #092682',
        textTransform: 'capitalize',
        fontWeight:400, 
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            fontSize:'12px',
        }
    },
    containerWrapper: {

        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            height: '614px',
          'width': '100%',
          'border-radius': '0',     
          'box-shadow': 'none',
          overflow: 'scroll',
          top: "365px",
        }
      },
      containerOverflow: {
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
          height: '100%',

        },
      },
    

}))
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '540px',
    height: '564px',
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    borderRadius: '10px',
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function ProceedToPayment() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
    );
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [opens, setOpens] = React.useState(false);

    const handleOpens = () => setOpens(true);
    const handleCloses = () => setOpens(false);

    const [opent, setOpent] = React.useState(false);

    const handleOpent = () => setOpent(true);
    const handleCloset = () => setOpent(false);
  return (
    <div className={classes.totalContainer} >
                
                <TableContainer className={classes.Totalwidth}  >
                    <Table>

                        <TableBody>

                            <TableRow>
                                <TableCell sx={{ width: '100px', paddingBottom: '70px' }} style={{ border: 'none' }}>
                                    <Typography >
                                        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrXoF41V52u7LH_BosyghIgkmkbm8dNI66H1xUoyuxRHi513SafrbuJ0ND-9bSDg4EwV0&usqp=CAU' width="98px" height="48px" ></img>
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ width: '500px' }} style={{ border: 'none' }} >
                                    <Typography className={classes.ApplicantHeading}> Application for SP Jain,Dubai  </Typography>
                                    <Typography className={classes.ApplicantDescription}>
                                        Your Application for <span className={classes.SpecificWord}>Mr.Suresh Sharma</span> for Undergraduation Program, <span className={classes.SpecificWord}>B.Tech in Civil Engineering</span> at
                                        MIT, Pune has been saved successfully!
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" style={{ border: 'none' }}>
                                    <Typography className={classes.AppFee}> Application Fee </Typography>
                                    <Typography className={classes.FeeAmount} >₹ 1000.00 </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 'none' }}>

                                </TableCell>
                                <TableCell >
                                    <Typography className={classes.Ques}>
                                       Discount Code Applied
                                    </Typography>
                                    <Typography className={classes.DiscountCode}>
                                       ADM298083
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.Minheight}>
                                    <Typography className={classes.AppFee}>Applicant Fee After Discount</Typography>
                                    <Typography className={classes.FeeAmount} >₹ 750.00 </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: 'none' }}></TableCell>
                                <TableCell sx={{ border: 'none' }}></TableCell>
                                <TableCell style={{ textAlign: 'right', border: 'none' }}><Typography className={classes.AppFee}>Seven Hundred and Fifty Rupees Only</Typography>
                                    <Button className={classes.button} onClick={handleOpen}>Pay</Button></TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal hideBackdrop={isMobileScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style} className={classes.containerWrapper}>
                    <Box className={classes.containerOverflow}>
                        <Box className={classes.ModalHead}>
                        <Box className={classes.modalheadBodyContainer}>
                        <Typography className={classes.model1Heading}>
                            Review Payment Request
                        </Typography>
                        <Typography className={classes.model1sub}>
                        Payment Request from VIVEK
                        </Typography> <br />
                        <Typography className={classes.ModelAmount}>
                         Amount: &nbsp;<span className={classes.ModelAmountValue}>₹ 750.00 </span>
                        </Typography> 
                        </Box>
                        </Box>
                        <Box className={classes.modalheadBodyContainer}>
                        <Typography className={classes.Model1subHeading}>
                        Payment Details
                        </Typography> <br />
                                <div sx={{pt:4}}>
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Name</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Aditya Kumar</Typography>
                                        </Grid>
                                    </Grid> 
                                    
                                    <Grid container  >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Course</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>B.Tech Mechanical Engineering</Typography>
                                        </Grid>
                                    </Grid> 
                                    
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>Campus</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>Campus Name</Typography>
                                        </Grid>
                                    </Grid> 
                                    <Grid container >
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormData}>APP</Typography>
                                        </Grid>
                                        <Grid item className={classes.FormDetailleft}>
                                            <Typography className={classes.FormResData}>96435611146</Typography>
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <br /> <br /> <br />
                                    <Grid container spacing={2} justifyContent='end'>
                                    <Grid item>
                    <Button  className={classes.button2} >Decline</Button>
                    </Grid>
                    <Grid item >
                    <Button className={classes.button} onClick={handleOpens} >Proceed to Pay</Button>
                    </Grid>
                 </Grid>

                        </Box>
                        </Box>
                    </Box>
                </Modal>
                <Modal hideBackdrop={isMobileScreen}
                    open={opens}
                    onClose={handleCloses}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style} className={classes.containerWrapper}>
                    <Box className={classes.containerOverflow}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center">
                            <Grid item >
                                <Typography style={{ marginTop: '100px' }}>
                                    <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="80px" height="80px" ></img>
                                </Typography>
                            </Grid>    <br />
                            <Grid item >
                                <Typography className={classes.m3context}>
                                    Payment Request Sent Succefully!
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3Url}>
                                You have succesfully done payment for SP Jain Dubai,Post Graduation Program,MBA
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3BackToHome}>
                                    Back to Home
                                </Typography>
                            </Grid>

                        </Grid>
                    </Box>
                    </Box>
                </Modal>
                <Modal hideBackdrop={isMobileScreen}
                    open={opent}
                    onClose={handleCloset}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <div style={{ textAlign: 'right' }}>
                            <CloseIcon onClick={() => { handleCloset(); }} />
                        </div>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center">
                            <Grid item >
                                <Typography style={{ marginTop: '100px' }}>
                                    <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="80px" height="80px" ></img>
                                </Typography>
                            </Grid>    <br />
                            <Grid item >
                                <Typography className={classes.m3context}>
                                    Payment Request Sent Succefully!
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3Url}>
                                    URL: https://razorpay.com/?utm_
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3BackToHome}>
                                    Back to Home
                                </Typography>
                            </Grid>

                        </Grid>
                    </Box>
                </Modal>
            </div>
  )
}
