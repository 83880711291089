import axios from 'axios';
import { KEY_ACCESS_TOKEN, KEY_CONFIG_URL } from '../Constants/constant';

let appConfig;
export let axiosInstance;
const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = (data) => {
  if (axiosInstance) {
    return axiosInstance;
  }
  appConfig = data[0]?.config;
  axiosInstance = axios.create({
    baseURL: `${appConfig.apiBaseUrl}`,
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      console.log('ApiService: Interceptor: ', error);
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      var config = {
        method: 'get',
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access_token);
        error.response.config.headers[
          'Authorization'
        ] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
};

export const getConfig = async () => {
  // const path = `${window.location.host}`;
  const path = 'dev-admissionportal.ken42.com';
  const res = await axios.get(`${configUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getAllPrograms = async () => {
  const path = `/salesforce?endpoint=services/apexrest/portal/retrieve/forms`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getFormByFormId = async (formId) => {
  const path = `/salesforce?endpoint=services/apexrest/portal/retrieve/form?formid=${formId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationsByApplicantId = async (applicantid) => {
  const path = `salesforce?endpoint=/services/apexrest/portal/retrieve/applications?applicantid=${applicantid}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationByApplicationId = async (applicationId) => {
  const path = `salesforce?endpoint=/services/apexrest/portal/retrieve/application?applicationid=${applicationId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getContactInfoByContactId = async (contactId) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/applicant?applicantid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createUpdateApplication = async (data) => {
  const path = `salesforce?endpoint=/services/apexrest/portal/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const deleteApplicationRecords = async (data) => {
  const delData = { data: data };
  const path = `salesforce?endpoint=/services/apexrest/portal/delete`;
  const res = await axiosInstance.delete(`${path}`, delData);
  return res && res.data ? res.data : null;
};

export const getDocuments = async (applicantId) => {
  const path = `salesforce?endpoint=/services/apexrest/portal/retrieve/documentstatus?applicantid=${applicantId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createDocuments = async (data) => {
  const path = `salesforce?endpoint=/services/apexrest/portal/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getNotifications = async (applicantId) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/notifications?applicantid=${applicantId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getImportantDates = async () => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/importantdates`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getFeeDetails = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalfee/retrieve/feedetails?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postFeeCoupon = async (data) => {
  const path = `salesforce?endpoint=/services/apexrest/portalfee/validatecoupon`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getOfferLetter = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalmisc/allofferletters?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postInitatePayment = async (data) => {
  const path = `https://api-v2.ken42.com/admission-portal/stage/api/payments/flywire/initiate`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getFeeReceipt = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalfee/retrieve/receipt?transactionid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getQuestionare = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalmisc/questionarie?contactid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postQuestionare = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalmisc/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getTransactionDetailsByTransactionId = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalfee/retrieve/transaction?paymentid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postAppliedCouponCode = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/AppliedCouponCodeAPI`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getRenderingDetails = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/renderingdetails?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicantByEmail = async (email) => {
  const path = `salesforce?endpoint=services/apexrest/otp/verifyemail?email=${email}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getDocumentsViaRecordId = async (id) => {
  const path = `salesforce?endpoint=services/apexrest/portalmisc/document?docid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getOfferletterViaApplicationId = async (id) => {
  const path = `salesforce?endpoint=services/apexrest/portalmisc/offerletter?applicationId=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getInterviewSlotsViaApplicationId = async (id) => {
  const path = `salesforce?endpoint=services/apexrest/interview/retrieve/slottimings?applicationid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};
