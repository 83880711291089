const routes = {
  login: '/login',
  signup: '/signup',
  loginOTP: '/login-otp',
  verifyNumber: '/verify-number',
  verifyEmail: '/verify-email',
  verifiedOTP: '/otp-verified',
  home:'home',
  profileDetails:'ProfileDetails',
  pending : 'Pending',
  Applications:'Applications',
  payment:'Payment',
  proceedToPayment:'ProceedToPayment',
  paymentSummary:'PaymentSummary'

};
export default routes;
