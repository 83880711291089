import React from 'react'
import { KenBreadcrumbs } from "../../Components/KenBreadcrumbs";
import Link from '@mui/material/Link';
import { Box, Grid, useTheme, useMediaQuery, Divider } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import KenSelect, { CustomIcon } from "../../Components/KenSelect";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import KenGrid from './KenGrid';
import Avatar from '@mui/material/Avatar';
import './App.css';
import KenTextField from '../../Components/KenTextField';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import InputAdornment from '@mui/material/InputAdornment';


const useStyles = makeStyles((theme) => ({
    content: {
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'left',
        color: '#061938'
    },

    Headtitle1:
    {
        textAlign: 'left',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '100%',
        color: '#061938',

    },
    filterIcon: {
        marginTop: 16,
        height: '24px',
        width: '24px',

        color: '#B3BAC5',
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            marginTop: 0,
        }
    },
    // select: {
    //     maxHeight: '48px'
    // },
    filterHeader: {
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            'padding-left': '8px',
            'padding-right': '16px',
            'font-weight': '500',
            'font-size': '16px',
            'line-height': '100%',
            'margin-top': '4px',
            'padding-bottom': '10px',
            'text-transform': 'capitalize',
            color: theme.palette.KenColors.neutral900,
        }
    },
    Dline: {
        color: '#7A869A',

    },
    select: {
        'background': '#F3F5F7',
        'border-radius': '4px',
    },
    selectText: {
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '16px',
        'color': '#7A869A',
    },
    list: {

        '& div': {
            maxHeight: 500,
            overflow: 'auto',
        },
    },


}))
const variants = [
    {
      id: 3,
      name: 'Voucher',
      slug: 'voucher',
      type: 'Main',
      locale: 'en',
      created_at: '2021-11-15T08:27:23.000Z',
      updated_at: '2021-11-15T08:27:23.000Z',
      cover: null,
    },
    {
      id: 1,
      name: 'Top Up',
      slug: 'top-up',
      type: 'Main',
      locale: 'en',
      created_at: '2021-11-15T08:26:44.000Z',
      updated_at: '2021-11-15T08:26:44.000Z',
      cover: null,
    },
    {
      id: 2,
      name: 'Game Key',
      slug: 'game-key',
      type: 'Main',
      locale: 'en',
      created_at: '2021-11-15T08:27:03.000Z',
      updated_at: '2021-11-15T08:27:03.000Z',
      cover: null,
    },
    {
      id: 12,
      name: 'Other',
      slug: 'other',
      type: 'SubMain',
      locale: 'en',
      created_at: '2021-11-15T08:30:50.000Z',
      updated_at: '2021-11-15T08:30:50.000Z',
      cover: null,
    },
    {
      id: 11,
      name: 'Nintendo',
      slug: 'nintendo',
      type: 'SubMain',
      locale: 'en',
      created_at: '2021-11-15T08:30:22.000Z',
      updated_at: '2021-11-15T08:30:22.000Z',
      cover: null,
    },
    {
      id: 10,
      name: 'Xbox',
      slug: 'xbox',
      type: 'SubMain',
      locale: 'en',
      created_at: '2021-11-15T08:30:08.000Z',
      updated_at: '2021-11-15T08:30:08.000Z',
      cover: null,
    },
  ];
const select = [

     
    {
        label: 'kisvinth',
        value: 'kisvinth',
    },
    {
        label: 'kavin',
        value: 'kavin',
    },
    {
        label: 'abc',
        value: 'abc',
    },
    {
        label: 'xyz',
        value: 'xyz',
    },
]


const data = [
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Paid',
        ApplStatus: 'Completed'

    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Not Paid',
        ApplStatus: 'Pending'
    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Paid',
        ApplStatus: 'Completed'
    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Not Paid',
        ApplStatus: 'Pending'
    },



];
const name = 'kavin'



const headerCellc = (value) => {
    return (
        <Typography style={{ fontWeight: 500, color: '#7A869A', fontSize: '14px', textAlign: 'left' }}>
            {value}
        </Typography>
    );
};
const headerCell = (value) => {
    return (
        <Typography style={{ fontWeight: 500, color: '#7A869A', fontSize: '14px', textAlign: 'center', marginRight: 200 }}>
            {value}
        </Typography>
    );
};
console.log(data)
const columns = [
    {
        Header: headerCell('Name'),
        accessor: 'name',
        Cell: ({ value }) => {
            return (

                <Grid container direction="row" spacing={2} style={{ textAlign: 'right' }} >
                    <Grid item style={{ marginTop: '8px' }} >
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    </Grid>
                    <Grid item style={{ marginTop: '14px' }}>
                        <Typography className='content1'>{value}</Typography>
                    </Grid>
                </Grid>
            )
        }
    },
    {
        Header: headerCellc("start Date of Appl."),
        accessor: 'startDate',
        Cell: ({ value }) => {
            return (
                <Typography className='content1'>{value}</Typography>
            )
        }
    },
    {
        Header: headerCellc("Last Date of Appl."),
        accessor: 'lastDate',
        Cell: ({ value }) => {
            return (
                <Typography className='content1'>{value}</Typography>
            )
        }
    },
    {
        Header: headerCellc("Program"),
        accessor: 'program',
        Cell: ({ value }) => {
            return (

                <Typography className='content1'>{value}</Typography>
            )
        }
    },
    {
        Header: headerCellc("Fee"),
        accessor: 'Fee',
        Cell: ({ value }) => {
            return (
                <Typography className='content1'>{value}</Typography>
            )
        }
    },
    {
        Header: headerCellc("Fee Status"),
        accessor: 'FeeStatus',
        Cell: ({ value }) => {
            if(value == 'Paid'){
            return (
                <>
                    { 
                            <Typography style={{ color: 'green',textAlign:'left',fontSize:'15px'}}>{value}</Typography>
                    }
                </>
                )
            } else { return (
            <>
                {
                    <Typography style={{ color: 'red',textAlign:'left',fontSize:'15px' }}>{value}</Typography>
                }
            </>
            )
            }
        }
    },
    {
        Header: headerCellc("Appl.status"),
        accessor: 'ApplStatus',
        Cell: ({ value }) =>  {
            if(value == 'Completed'){
            return (
                <>
                    { 
                            <Typography style={{ color: 'green',textAlign:'left',fontSize:'15px'}}>{value}</Typography>
                    }
                </>
                )
            } else { return (
            <>
                {
                    <Typography style={{ color: 'red',textAlign:'left',fontSize:'15px' }}>{value}</Typography>
                }
            </>
            )
            }
        }
    },

]
export default function Applications(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
      theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const [variantName, setVariantName] = React.useState([
  
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);

  }
 

    function breadCrumTitle(){
        let result="";
        result= "Total Applicants";
         return result
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/anotherHome">
         Dashboard
        </Link>,
       
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href="/anotherHome"
        >{
            breadCrumTitle()
        }
        </Link>,
      ];
  return (
    <div>
    <KenBreadcrumbs breadcrumbs={breadcrumbs} />
    <>
              <Box sx={{ mt: 6, pb: 6 }}

                    width={isMobileScreen ? '100%' : undefined}>
                    <Typography className={classes.Headtitle1}>Total Applications</Typography> <br />
                    <Typography className={classes.content}>Hi! Suraj ,this page shows the total number of applicants.</Typography>
                </Box>

                <Box sx={{ mt: 3, pb: 2 }}
                    padding={isMobileScreen ? '0px 10px 16px 10px' : undefined}
                    width={isMobileScreen ? '100%' : undefined}>
                {isMobileScreen ? <Box className={classes.filterHeader}>
                    <Typography>
                        Filter
                    </Typography>
                    <Box>
                        <FilterAltOffIcon className={classes.filterIcon} />
                    </Box>
                </Box>
                    : undefined}
                
                    <Grid
                        container
                        spacing={1}
                        width={isMobileScreen ? '100%' : undefined}
                        flexDirection={isMobileScreen ? 'column' : undefined}
                        marginRight={isMobileScreen ? '0' : undefined}>
                        <Grid item md={4} sm={12}>

                            <KenSelect
                                options={select}
                                handleChange={(e) => {
                                }}
                                
                              

                                inputProps={{
                                    className: classes.selectText,
                                   
                                }}
                                textFieldProps={{
                                    className: classes.select,
                                    SelectProps: {
                                        IconComponent: CustomIcon,
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <KenSelect
                                options={select}
                                handleChange={(e) => {
                                }}
                                
                                inputProps={{
                                    className: classes.selectText,
                                    

                                   
                                }}
                                textFieldProps={{
                                    className: classes.select,
                                    SelectProps: {
                                        IconComponent: CustomIcon,
                                    }
                                }} />
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                            <KenTextField
                                placeholder={"Search..."}
                                handleChange={(e) => {
                                }}
                                inputProps={{
                                    className: classes.selectText,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccessTimeFilledIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                textFieldProps={{
                                    className: classes.select,
                                    SelectProps: {
                                        IconComponent: CustomIcon,
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={0.5}>
                            {!isMobileScreen ? <Grid item md={1}>
                                <FilterAltOffIcon className={classes.filterIcon} />
                            </Grid> : undefined}
                        </Grid>
                    </Grid >
                    <br />
                    <hr className={classes.Dline} />
                </Box>
                <Box sx={{ mt: 3, pb: 2 }}
                    padding={isMobileScreen ? '0px 16px 16px 16px' : undefined}
                    width={isMobileScreen ? '100%' : undefined}>

                    <div className={classes.list}>
                        <div>
                            <KenGrid sx={{'&.makeStyles-head-46':{
                                textTranform:'lowerCase'
                            }}}
                                columns={columns}
                                data={data}
                                pagination={{ disabled: false }}
                                toolbarDisabled
                            />
                        </div>
                    </div>
                </Box>

            </>
    </div>
  )
}

