import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Verify from '../common/Verify';
import { AuthContext } from '../../../Context/authContext';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { requestForOTP, verifyOTP } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../../../Constants/routes';
import validator from 'validator';
import KenLoader from '../../../Components/KenLoader';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
}));
export default function LoginOTP(props) {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(null);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.login);
    } else {
      setIsEmail(validator.isEmail(navigateState.currentId));
    }
  }, [navigate, navigateState?.currentId, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.currentId,
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      // TODO: load token
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: navigateState.id,
            applicantEmail: res?.applicantEmail,
            applicantId: res?.applicantId,
            applicantName: res?.applicantName,
            applicantDob: res?.applicantDob,
          },
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {typeof isEmail !== 'boolean' ? (
        <KenLoader />
      ) : (
        <Box className={classes.container}>
          <form>
            <Verify
              loading={loading}
              from="loginOTP"
              title="Verify OTP"
              subTitle={
                <>
                  <span>
                    {isEmail
                      ? 'Please enter the OTP sent to your registered Email ID.'
                      : 'Please entre the OTP sent on your registered mobile number'}
                  </span>
                  {!isEmail && (
                    <strong style={{ marginLeft: '5px' }}>
                      *******{navigateState.currentId.slice(-3)}.
                    </strong>
                  )}
                </>
              }
              submitText="Verify & Log In"
              onVerify={(otp) => {
                onSubmit({ otp });
              }}
              resendOTP={async () => {
                await requestForOTP({ id: navigateState.currentId });
              }}
            ></Verify>
          </form>
        </Box>
      )}
    </>
  );
}
