
import { useState } from 'react';
import KenButton from '../../Components/KenButton';
import Tasks from './modaltask';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
// import BasicGrid from './Task';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({

  log: {
    textAlign:'left',
  
    fontfamily: 'Poppins',
    fontstyle: 'normal',
    fontweight: '400',
    fontsize: '18px',
    lineheight: '100%',
    color: '#061938',
    alignself: 'stretch',
  },
  down: {
    textAlign:'left',
    
    
    fontfamily: 'Poppins',
    fontstyle: 'normal',
    fontweight: '400',
    fontsize: '14px',
    lineheight: '100%',
    color: '#A8AFBC',
  },
  icon: {
    
    // paddingLeft: "60px",
    
    width: '28px',
    height: '28px',
    // background: 'rgba(223, 232, 255, 0.6)',
    borderradius: '8px',
  },

  boxi:{
    '& ::-webkit-scrollbar': {
        
      width: 8,
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: 5,
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#B8B8B8',
      borderRadius: 10,
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#D0D0D0',
    },
  },
 
  boxii: {
      maxHeight: 450,
      height: 450,
      overflow: 'auto',
      padding:'0px 20px 20px 0px'
    },
  
 
  noTaskButton: {
  
   
    fontfamily: 'Poppins',
    fontstyle: 'normal',
    fontweight: '500',
    fontsize: '16px',
    lineheight: '100%',
    color: '#092682'
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function createData(loginID,Date) {
  return {loginID,Date};
}

const user={
  loginID:'Login ID Register',
  Date:'15 Jan | Thursday| 9:30-10:00'
}
const rows = [
  createData(user.loginID,user.Date),
  createData(user.loginID,user.Date),
  createData(user.loginID,user.Date),
  createData(user.loginID,user.Date),
  createData(user.loginID,user.Date),
  createData(user.loginID,user.Date),
];

export default function Patners() {
  const classes = useStyles();
  const [showTask, setshowTask] = useState(false);
  const [saving, setSaving] = useState(false);
  return (
    <div>
      {showTask && (
        <Tasks
          saving={saving}
          showTask={showTask}
          onCancel={() => {
            setshowTask(false);
          }}
        />
      )}
      
          <Box className={classes.boxi} sx={{ flexGrow: 1 }}>
            <div className={classes.boxii}>

            {rows.map((data) => (
              <>
            <Grid container >
              <Grid item xs={2}>
                <Typography className={classes.icon}><InboxIcon /></Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.log}>{data.loginID}</Typography>
                <br></br>
                <Typography className={classes.down}>{data.Date}</Typography>
              </Grid>
              <Divider  style={{ paddingTop: "18px",width:'304px' }} />
            </Grid>
            <br></br>
            </>
            ))}           
          </div>
          </Box>
          <div style={{textAlign:'left'}}>
          <KenButton 
              style={{backgroundColor:'white'}}
              onClick={() => {
                setshowTask(true);
              }}
            >
              + Add New Tasks
            </KenButton>
            </div>
       

    </div>
  )
}






