import React from 'react';
import { Box, Grid, useTheme, useMediaQuery, Divider } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import json from './table.json';
import Paper from '@mui/material/Paper';
import Pending from './pending';
import Profile from './Dashboard';
import Patners from './AddTask';
import { AddTask } from '@mui/icons-material';

function createData(name, program, department, application) {
  return { name, program, department, application };
}

const rows = [
  createData(json.name, json.program, json.department, json.Application),
  createData(json.name, json.program, json.department, json.Application),
  createData(json.name, json.program, json.department, json.Application),
  createData(json.name, json.program, json.department, json.Application),
  createData(json.name, json.program, json.department, json.Application),
  createData(json.name, json.program, json.department, json.Application),
];

const useStyles = makeStyles((theme) => ({
  Headtitle: {
    height: '18px',
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '100%',
    color: '#061938',
  },
  htableCell: {
    border: 'none',
    fontWeight: 500,
    fontSize: '14px',
    color: '#7A869A',
    backgroundColor: '#F5F5F5',
  },
  btableCell: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#061938',
    borderBottom: '1px solid #A8AFBC',
  },
  bAtableCell: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#7A869A',
  },
  bdtableCell: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#2862FF',
    textDecorationLine: 'underline',
    borderBottom: '1px solid #A8AFBC',
  },
  list: {
    '& ::-webkit-scrollbar': {
      width: 8,
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: 5,
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#B8B8B8',
      borderRadius: 10,
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#D0D0D0',
    },
    '& div': {
      maxHeight: 500,
      height: 500,
      overflow: 'auto',
      padding: '0px 20px 20px 0px',
    },
  },
  root: {
    [theme.breakpoints.only('xs')]: {
      padding: 4,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
      margin: 0,
      width: '100%',
    },
  },
}));

export default function AnotherHome() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  return (
    <React.Fragment>
      <Profile />
      <Paper elevation={0} sx={{ pl: 1, mt: 6 }}>
        <Grid container spacing={2} width={isMobileScreen ? '100%' : undefined}>
          <Grid item md={8.5} sx={12}>
            <Typography className={classes.Headtitle}>
              {' '}
              Applications in Progress{' '}
            </Typography>
            <br />
            <br />

            <TableContainer className={classes.list}>
              <div>
                <Table stickyHeader className={classes.another}>
                  <TableHead>
                    <TableRow className={classes.htableRow}>
                      <TableCell className={classes.htableCell}>Name</TableCell>
                      <TableCell className={classes.htableCell}>
                        Program
                      </TableCell>
                      <TableCell className={classes.htableCell}>
                        Application
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell className={classes.btableCell}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.btableCell}>
                          <br />
                          {row.program} <br />{' '}
                          <span className={classes.bAtableCell}>
                            {row.department}
                          </span>
                        </TableCell>
                        <TableCell className={classes.bdtableCell}>
                          <span href="www.wwe.com">{row.application}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>
          <Grid item md={3.5} xs={12}>
            <Typography className={classes.Headtitle}> Your Task's </Typography>
            <Box sx={{ pt: 7 }} width={isMobileScreen ? '100%' : undefined}>
              <Patners />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
