import React from 'react';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import {
  Box,
  Card,
  Button,
  CardContent,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { alignProperty } from '@mui/material/styles/cssUtils';
// import Card from '@mui/material/card';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'left',
    fontSize: '24px',
    color: '#061938',
    fontWeight: 500,
  },
  heading1: {
    textAlign: 'left',
    fontSize: '16px',
    color: '#061938',
    fontWeight: 400,
  },
  butto: {
    colour: '#092682',
    borderRadius: '20px',
    'width':'max-content'
    // fontSize:'12px'
  },
  cip1: {
    border: 'primary',
    height: '118px',
    display: 'flex',
    borderRadius: '12px',
    color: '#3F61BA',
    boxShadow: 'none',
    border: '2px solid #5686FF',
  },
  cip2: {
    border: 'primary',
    height: '118px',

    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#FFEBDC',
    color: '#E07B2F',
    boxShadow: 'none',
  },
  cip3: {
    border: 'primary',
    height: '118px',

    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#FFFBEE',
    color: '#C59809',
    boxShadow: 'none',
  },
  cip4: {
    border: 'primary',
    height: '118px',

    display: 'flex',
    borderRadius: '12px',
    backgroundColor: 'rgba(82, 193, 90, 0.18)',
    color: '#0F9815',
    boxShadow: 'none',
  },
}));
export default function Profile() {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={10} xs={12}>
          <Typography className={classes.heading}>Welcome Partner!</Typography>{' '}
          <br />
          <Typography className={classes.heading1}>
            Hi! Megha ,Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            At pellentesque dignissim.
          </Typography>
        </Grid>{' '}
        <br />
        <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
          <Button className={classes.butto} variant="contained">
            Start New Application
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ marginTop: '10px' }}
        justifyContent="center"
      >
        <Grid
          item
          md={3.4}
          sm={6}
          xs={12}
          onClick={() =>
            navigate('/applications', {
              state: {
                filter: 'T',
              },
            })
          }
        >
          <Card className={classes.cip1} href="#basic-chip" clickable>
            <Box>
              <Grid container sx={{ padding: '20px 0px 0px 30px' }}>
                <Grid item>
                  <Typography
                    style={{ fontSize: '40px', weight: 500, textAlign: 'left' }}
                  >
                    45
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', padding: '0px 0px 10px 30px' }}>
                <Typography>
                  <PeopleOutlineIcon />
                </Typography>
                <Typography style={{ padding: '10px', marginTop: '-10px' }}>
                  Total Applicants
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          md={2.8}
          xs={12}
          sm={6}
          onClick={() =>
            navigate('/pending', {
              state: {
                filter: 'P',
              },
            })
          }
        >
          <Card className={classes.cip2} href="#basic-chip" clickable>
            <Box>
              <Grid container sx={{ padding: '20px 0px 0px 30px' }}>
                <Grid item >
                  <Typography
                    style={{ fontSize: '40px', weight: 500, textAlign: 'left' }}
                  >
                    02
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', padding: '0px 0px 10px 30px' }}>
                <Typography>
                  <PeopleOutlineIcon />
                </Typography>
                <Typography style={{ padding: '10px', marginTop: '-10px' }}>
                  {' '}
                  Pending
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item md={2.8} sm={6} xs={12}>
          <Card className={classes.cip3} href="#basic-chip" clickable>
            <Box>
              <Grid container spacing={2} sx={{ mt: '1px' }}>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={2}>
                  <Typography style={{ fontSize: '40px', weight: 500 }}>
                    20
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', padding: '0px 30px 0px 30px' }}>
                <Typography>
                  <RotateLeftOutlinedIcon
                    style={{ height: '20px', width: '20px' }}
                  />
                </Typography>
                <Typography style={{ paddingLeft: '10px', marginTop: '-3px' }}>
                  Under progress
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item md={2.8} sm={6} xs={12}>
          <Card className={classes.cip4} href="#basic-chip" clickable>
            <Box>
              <Grid container spacing={2} sx={{ mt: '1px' }}>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={2}>
                  <Typography style={{ fontSize: '40px', weight: 500 }}>
                    23
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', padding: '0px 30px 0px 30px' }}>
                <Typography>
                  {' '}
                  <CheckCircleOutlineIcon
                    style={{ height: '20px', width: '20px' }}
                  />
                </Typography>
                <Typography style={{ paddingLeft: '10px', marginTop: '-3px' }}>
                  Completed
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
