import React from 'react';
import Paper from '@mui/material/Paper';
import { Fade,Box, Grid, useTheme, useMediaQuery, Divider, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import KenTextField from '../../Components/KenTextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import KenTextLabel from '../../Components/KenTextLabel';
import Checkbox from '@mui/material/Checkbox';
import KenSelect from '../../Components/KenSelect';
import { Controller, useForm } from 'react-hook-form';
import KenInput from '../../Components/KenInput';





const useStyles = makeStyles((theme) => ({
    totalContainer:{
        display: '-webkit-inline-box', 
        marginLeft: '-70px',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            width: '100%',
            marginLeft: '0px',
            
        }
    },
    Totalwidth:{
        width: '960px',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
    
        }
    },
    MiddleGrid: {
        textAlign: 'left',
        borderBottom: '1px solid #B3BAC5'
    },
    ApplicantHeading: {
        fontWeight: 600,
        fontSize: '20px',
        color: '#092682',
        lineHeight: '200%'
    },
    ApplicantDescription: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '200%',
        color: '#505F79'
    },
    SpecificWord: {
        fontWeight: 600,
        color: 'grey'
    },
    AppFee: {
        fontWeight: 400,
        textAlign: 'right',
        color: '#7A869A',
        lineHeight: '200%',
        fontSize: '14px',
        border: 'none'
    },
    FeeAmount: {
        fontWeight: 600,
        fontSize: '18px',
        textAlign: 'right',
        color: '#505F79',
        lineHeight: '200%',
    },
    Ques: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '200%',
        color: '#505F79'
    },
    QuesApply: {
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '16px',
        color: '#092682',
        marginTop: '6px'
    },
    tableRow: {
        cursor: "pointer",
        border: "none",
        marginTop: "100px"
    },
    button: {
        marginTop: '8px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#092682'
        }
    },
    buttonModel: {
        width: '279px',
        background: '#092682',
        'border-radius': '4px',
        'color': 'white',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#092682'
        }

    },
    modelHeading: {
        fontWeight: 600,
        textAlign: 'left',
        lineHeight: '200%',
        fontSize: '24px',
        color: '#092682',
    },
    ModelAmount: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '24px',
        color: '#505F79',
        lineHeight: '200%',
    },
    ModelAmountValue: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        color: '#061938'
    },
    ReminderText: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#505F79'
    },
    sendremainder: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        color: '#061938',
        marginTop: '9px',
    },
    remaindercontent: {
        fontWeight: 400,
        fontSize: '14px',
        color: '#7A869A',

    },
    Minheight: {
        height: '250px'
    },
    Model2PayDetail: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#092682',
    },
    payDetailContent: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#505F79',
        lineHeight: '200%'
    },
    m3context: {
        fontWeight: 600,
        fontSize: '20px',
        color: '#092682',
        lineHeight: '200%',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            textAlign:'left',
            fontSize: '16px',
        }
    },  
    m3Url: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#092682',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            textAlign:'left'
        }
    },
    m3BackToHome: {
        marginTop: '200px',
        fontWeight: 400,
        fontSize: '14px',
        color: '#505F79',
    },
    containerWrapper: {

        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            height: '80%',
          'width': '100%',
          'border-radius': '0',     
          'box-shadow': 'none',
          overflow: 'scroll',
          top: "365px",
        }
      },
      containerOverflow: {
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
          height: '100%',

        },
      },

}))
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '748px',
    height: '648px',
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Payment() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(
        theme.breakpoints.down(TABLET_BREAKPOINT)
    );
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [opens, setOpens] = React.useState(false);

    const handleOpens = () => setOpens(true);
    const handleCloses = () => setOpens(false);

    const [opent, setOpent] = React.useState(false);

    const handleOpent = () => setOpent(true);
    const handleCloset = () => setOpent(false);

    return (
        <React.Fragment>
            <div className={classes.totalContainer} >
                
                <TableContainer className={classes.Totalwidth}  >
                    <Table>

                        <TableBody>

                            <TableRow>
                                <TableCell sx={{ width: '100px', paddingBottom: '70px' }} style={{ border: 'none' }}>
                                    <Typography >
                                        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrXoF41V52u7LH_BosyghIgkmkbm8dNI66H1xUoyuxRHi513SafrbuJ0ND-9bSDg4EwV0&usqp=CAU' width="98px" height="48px" ></img>
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ width: '500px' }} style={{ border: 'none' }} >
                                    <Typography className={classes.ApplicantHeading}> Application for SP Jain,Dubai  </Typography>
                                    <Typography className={classes.ApplicantDescription}>
                                        Your Application for <span className={classes.SpecificWord}>Mr.Suresh Sharma</span> for Undergraduation Program, <span className={classes.SpecificWord}>B.Tech in Civil Engineering</span> at
                                        MIT, Pune has been saved successfully!
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" style={{ border: 'none' }}>
                                    <Typography className={classes.AppFee}> Application Fee </Typography>
                                    <Typography className={classes.FeeAmount} >₹ 1000.00 </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={{ border: 'none' }}>

                                </TableCell>
                                <TableCell >
                                    <Typography className={classes.Ques}>
                                        Do you have a discount code?
                                    </Typography>
                                    <Grid container spacing={4}  >
                                        <Grid item sx={{ maxWidth: '260px' }}>
                                            <KenTextField placeholder={"Enter Discount Code"} />
                                        </Grid>
                                        <Grid item >
                                            <Typography className={classes.QuesApply}>Apply</Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.Minheight}>
                                    <Typography className={classes.AppFee}>Applicant Fee After Discount</Typography>
                                    <Typography className={classes.FeeAmount} >₹ 750.00 </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: 'none' }}></TableCell>
                                <TableCell sx={{ border: 'none' }}></TableCell>
                                <TableCell style={{ textAlign: 'right', border: 'none' }}><Typography className={classes.AppFee}>Seven Hundred and Fifty Rupees Only</Typography>
                                    <Button className={classes.button} onClick={handleOpen}>Generate Payment Link</Button></TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal hideBackdrop={isMobileScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={open}>
                    
                    <Box sx={style} className={classes.containerWrapper}>
                    <Box className={classes.containerOverflow}>
                        <Grid container>
                            <Grid item xs={11.5}>
                                <Typography className={classes.modelHeading}>
                                    Create Payment Link
                                </Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <CloseIcon onClick={() => { handleClose(); }} />
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid item xs={9}>
                                <Typography className={classes.ApplicantDescription}>
                                    Your Application for <span className={classes.SpecificWord}>Mr.Suresh Sharma</span> for Undergraduation Program, <span className={classes.SpecificWord}>B.Tech in Civil Engineering</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                        <Typography className={classes.ModelAmount}>
                            Amount: &nbsp;<span className={classes.ModelAmountValue}>₹1000</span>
                        </Typography>
                        <Divider />
                        <Box sx={{ pt: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} >
                                    <KenTextLabel
                                        label="Name"
                                        required />
                                    <KenTextField placeholder="Enter Name" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={1}>

                                        <Grid item md={3}>
                                            <KenTextLabel
                                                label="Mobile"
                                                required />
                                            <KenSelect
                                                required
                                                name="countryCode"
                                                label=""
                                            />
                                        </Grid>
                                        <Grid item md={9} >
                                            <KenTextLabel />
                                            <KenInput
                                                name="phone"
                                                label=""
                                                placeholder="Enter Number"
                                                style={{ marginTop: '7px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} sx={{ pt: 3 }}>
                                <Grid item md={6} xs={12}>
                                    <KenTextLabel
                                        label="Email ID"
                                        required />
                                    <KenTextField placeholder="Enter Email ID" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <KenTextLabel
                                        label="Expire on"
                                        required />
                                    <KenTextField placeholder="Select Date & Time" />
                                </Grid>
                            </Grid>
                        </Box >
                        <Typography sx={{ pt: 3 }} className={classes.ReminderText}>Reminders</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={isMobileScreen?2:1}><Checkbox {...label} color="success" /></Grid>
                            <Grid item xs={11}>
                                <Typography className={classes.sendremainder} >Send Auto Reminder</Typography>
                                <Typography className={classes.remaindercontent}>Reminder will be sent to the recepient 2 hours before the link expires </Typography>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '30px', textAlign: 'right' }}>
                            <Button className={classes.buttonModel} onClick={() => { handleClose(); handleOpens(); }}>Share Payment Link</Button>
                        </div>
                    </Box>
                    </Box>
                    </Fade>
                </Modal>
                <Modal hideBackdrop={isMobileScreen}
                    open={opens}
                    onClose={handleCloses}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style} className={classes.containerWrapper}>
                    <Box className={classes.containerOverflow}>
                        <Typography className={classes.modelHeading}>
                            Create Payment Link
                        </Typography>
                        <Divider />

                        <Grid container sx={{ pt: 4 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Payment For:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>Monika Sharma <br /> B.Tech | Mechanical Engineering <br /> monika99@kmail.com <br />+91 9988776655</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 2 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Amount:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>₹ 1000</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 2 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Link URL:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>https://razorpay.com/?utm_</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 4 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Link Expires On:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>Thu, Apr 22  5:00 pm-6:00 pm</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 4 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Receipt No:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>AB7844G9D</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 4 }}>
                            <Grid item xs={isMobileScreen?6:3.5}>
                                <Typography className={classes.Model2PayDetail}>Created By:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.payDetailContent}>Vivek A</Typography>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '30px', textAlign: 'right' }}>
                            <Button className={classes.buttonModel} onClick={() => { handleOpent(); handleCloses(); }}>Share Payment Link</Button>
                        </div>
                    </Box>
                    </Box>
                </Modal>
                <Modal hideBackdrop={isMobileScreen}
                    open={opent}
                    onClose={handleCloset}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style} className={classes.containerWrapper}>
                    <Box className={classes.containerOverflow}>
                        <div style={{ textAlign: 'right' }}>
                            <CloseIcon onClick={() => { handleCloset(); }} />
                        </div>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center">
                            <Grid item >
                                <Typography style={{ marginTop: '100px' }}>
                                    <img src='https://cdn-icons-png.flaticon.com/128/3699/3699516.png' width="80px" height="80px" ></img>
                                </Typography>
                            </Grid>    <br />
                            <Grid item >
                                <Typography className={classes.m3context}>
                                    Payment Request Sent Succefully!
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3Url}>
                                    URL: https://razorpay.com/?utm_
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography className={classes.m3BackToHome}>
                                    Back to Home
                                </Typography>
                            </Grid>

                        </Grid>
                    </Box>
                    </Box>
                </Modal>
            </div>
        </React.Fragment>
    )
}
