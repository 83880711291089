import React, { useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Calendar as RCalendar } from 'react-calendar';
import moment from 'moment';
import LeftArrow from '../../Assets/Svg/left-arrow.svg';
import RightArrow from '../../Assets/Svg/right-arrow.svg';
import { activeTheme } from '../../Utils/Themes/themeHelper';
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  calendarWrapper: {
    maxWidth: 290,
    width: '100%',
    background: theme.palette.KenColors.white,
    'box-shadow':
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '12px',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 330,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      maxWidth: 330,
    },
  },
  calendar: {
    border: 0,
    'font-weight': 600,
    'font-size': '14px',
    'text-align': 'center',
    'border-radius': '12px',
    margin: '2px auto',
    width: '100%',

    '& .react-calendar__navigation': {
      background: '#F8F8F8',
      minHeight: 53,
    },
    '& .react-calendar__viewContainer': {
      margin: 'auto',
      width: '89%',
    },
    '& .react-calendar__navigation__label__labelText': {
      'font-family': 'Poppins',
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '15.7241px',
      'line-height': '24px',
      'text-align': 'center',
      color: activeTheme.calendar.calendarTitle,
    },
    '& .react-calendar__month-view__weekdays__weekday abbr': {
      'font-family': 'Poppins',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '11.7931px',
      // "line-height": "16px",
      'text-align': 'center',
      color: activeTheme.calendar.weekdayColor,
    },
    '& abbr': {
      textDecoration: 'none !important',
    },
    '& .react-calendar__tile--now': {
      background: 'transparent !important',
    },
    '& .react-calendar__month-view__days__day--weekend, .react-calendar__tile':
      {
        'font-family': 'Poppins',
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '13.7586px',
        'text-align': 'center',
        color: `${activeTheme.calendar.activeDayColor}`,
        minHeight: '50px',
      },
    '& .react-calendar__tile--active, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus':
      {
        background: 'transparent',
        '& abbr': {
          borderRadius: '50%',
          background: `${activeTheme.calendar.highlightedDayColor} !important`,
          color: 'white !important',
          height: '30px',
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    '& .disabled': {
      'font-family': 'Poppins',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '13.7586px',
      'line-height': '20px',
      'text-align': 'center',
      color: '#B3BAC5',
    },
    '& .highlight abbr': {
      borderRadius: '50%',
      background: `${activeTheme.calendar.highlightedDayColor} !important`,
      color: 'white !important',
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .semi-highlight abbr': {
      borderRadius: '50%',
      background: `${activeTheme.calendar.semiHighlight} !important`,
      color: `${activeTheme.calendar.semiHighlightColor} !important`,
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .currentWeekDay abbr': {
      color: '#061938',
    },
  },
  title: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    color: theme.palette.KenColors.neutral900,
    'margin-bottom': '16px',
    'margin-top': '24px',
    'text-align': 'left',
  },
}));

export default function CalendarComponent({
  mark = [],
  semiHighlight = [],
  calendarWrapperClassName,
  rows,
  setDate = {},
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  useEffect(() => {
    try {
      document
        .getElementsByClassName('react-calendar__month-view__weekdays')[0]
        // moment().isoWeekday() returns 1 to 7 and we need to map it to 0 to 6
        .children[moment().isoWeekday() - 1].classList.add('currentWeekDay');
    } catch (error) {}
  }, []);

  return (
    <>
      {isMobileScreen && (
        <Typography className={classes.title}>Calendar</Typography>
      )}
      <Box
        className={`${classes.calendarWrapper} ${
          calendarWrapperClassName ? calendarWrapperClassName : ''
        }`}
      >
        <RCalendar
          showNeighboringMonth={false}
          next2Label={null}
          prev2Label={null}
          prevLabel={<img src={LeftArrow} alt="" />}
          nextLabel={<img src={RightArrow} alt="" />}
          className={classes.calendar}
          tileClassName={({ date, view }) => {
            if (
              rows?.find(
                (x) =>
                  moment(x.targetDate || x.interviewDate).format('DD-MM-YY') ===
                  moment(date).format('DD-MM-YY')
              )
            ) {
              return 'highlight';
            }

            // if (semiHighlight.find((x) => x === moment(date).format("DD"))) {
            //   return "semi-highlight";
            // }
          }}
          onChange={(e) => {
            setDate(e);
          }}
          {...rest}
        />
      </Box>
    </>
  );
}
