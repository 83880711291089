import React from 'react';
import { Box, Grid, useTheme, useMediaQuery, Divider } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import KenSelect, { CustomIcon } from "../../Components/KenSelect";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import KenGrid from './KenGrid';
import Avatar from '@mui/material/Avatar';
import KenTextField from '../../Components/KenTextField';
import { KenBreadcrumbs } from "../../Components/KenBreadcrumbs";
import Link from '@mui/material/Link';
import './App.css';


const useStyles = makeStyles((theme) => ({
    content: {
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'left',
        color: '#061938'
    },
    Headtitle1:
    {
        textAlign: 'left',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '100%',
        color: '#061938',
    },
    filterIcon: {
        marginTop: 16,
        height: '24px',
        width: '24px',
        color: '#B3BAC5',
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            marginTop: 0,
        }
    },
    filterHeader: {
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          'padding-left':'8px',
          'padding-right':'16px', 
          'font-weight': '500',
          'font-size': '16px',
          'line-height': '100%',
           'margin-top':'4px',
           'padding-bottom':'10px',
          'text-transform': 'capitalize',
          color: theme.palette.KenColors.neutral900,
        }
      },
    Dline: {
        color: '#7A869A',
    },
    list: {
        
        '& div': {
          maxHeight: 500,
         
          overflow: 'auto',
          
        },
      },
    select: {
        'background': '#F3F5F7',
        'border-radius': '4px',
    },
    selectText: {
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '16px',
        'color': '#7A869A',
    },
}))

const data = [
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'paid',
        ApplStatus: 'completed'

    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Not paid',
        ApplStatus: 'Pending'
    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'paid',
        ApplStatus: 'completed'
    },
    {
        name: 'Monika Sharma',
        startDate: '12 July 2022',
        lastDate: '12 Aug 2022',
        program: 'M Tech',
        Fee: '$300',
        FeeStatus: 'Not paid',
        ApplStatus: 'Pending'
    },



];
  
  const options = [
    {
        label:'kisvinth',
        value:'kisvinth',
    },
    {
        label:'kavin',
        value:'kavin',
    },
    {
        label:'abc',
        value:'abc',
    },
    {
        label:'xyz',
        value:'xyz',
    },
]

  const headerCellc = (value) => {
    return (
        <Typography style={{fontWeight:500,color: '#7A869A',fontSize:'14px',textAlign:'left'}}>
            {value}
        </Typography>
    );
};
const headerCell = (value) => {
    return (
        <Typography style={{fontWeight:500,color: '#7A869A',fontSize:'14px',textAlign:'center',marginRight:200}}>
            {value}
        </Typography>
    );
};
  
  console.log(data)
  const columns = [
      {
          Header: headerCell('Name'),
          accessor: 'name',
          Cell: ({value}) => {
              return (
                            
                <Grid container direction="row"  spacing={2} style={{textAlign:'right'}} >
                <Grid item style={{marginTop:'8px'}} >
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </Grid>
                <Grid item style={{marginTop:'14px'}}>
                <Typography className='content1'>{value}</Typography>
                </Grid>
            </Grid> 
                               
                     )
          }
      },
      {   
          Header:headerCellc("start Date of Appl."),
          accessor:'sdate',
          Cell:()=>{
              return(
                  <Typography className='content1'>12 July 2022</Typography>
              )
          }
      },
      {
          Header:headerCellc("Last Date of Appl."),
          accessor:'ldate',
          Cell:()=>{
              return(
                  <Typography className='content1'>12 July 2022</Typography>
              )
          }
      },
      {
          Header:headerCellc("Program"),
          accessor:'program',
          Cell:()=>{
              return(
                  <Typography className='content1'>M.Tech</Typography>
              )
          }
      },
      {
          Header:headerCellc("Fee"),
          accessor:'fee',
          Cell:()=>{
              return(
                  <Typography className='content1'>022</Typography>
              )
          }
      },
      {
          Header:headerCellc("Fee Status"),
          accessor:'fee status',
          Cell:()=>{
              return(
                  <Typography className='content1' style={{color:'red'}}>paid</Typography>
              )
          }
      },
      {
          Header:headerCellc("Appl.status"),
          accessor:'app statys',
          Cell:()=>{
              return(
                  <Typography className='content1' style={{color:'red'}}>completed</Typography>
              )
          }
      },
      
  ]
  const select = [
    {
        label: 'kisvinth',
        value: '1',
    },
    {
        label: 'kavin',
        value: '2',
    },
    {
        label: 'abc',
        value: '3',
    },
    {
        label: 'xyz',
        value: '4',
    },
]
export default function Pending() {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(
        theme.breakpoints.down(TABLET_BREAKPOINT)
    );
    const classes = useStyles();
    function breadCrumTitle(){
        let result="";
        result= "Total Applicant";
         return result
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/anotherHome">
         Dashboard
        </Link>,
       
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href="/anotherHome"
        >{
            breadCrumTitle()
        }
        </Link>,
      ];
  return (
    <React.Fragment>
        <Box sx={{  pb: 6 }} width={isMobileScreen ? '100%' : undefined}> 
        <KenBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
           <Typography className={classes.Headtitle1}>Pending Applicants</Typography> <br />
            <Typography className={classes.content}>Hi! Suraj ,this page shows the total number of applicants</Typography>
            <br /><br />
            {isMobileScreen ? <Box className={classes.filterHeader}>
                    <Typography>
                        Filter
                    </Typography>
                    <Box>
                        <FilterAltOffIcon className={classes.filterIcon} />
                    </Box>
                </Box>
                    : undefined}
            <Grid
                container
                spacing={2}
                width={isMobileScreen ? '100%' : undefined}
                flexDirection={isMobileScreen ? 'column' : undefined}
                marginRight={isMobileScreen ? '0' : undefined}>
                <Grid item md={4}>

                    <KenSelect
                       options={options}
                        handleChange={(e) => {

                        }}
                       
                        inputProps={{
                            className: classes.selectText,
                        }}
                        textFieldProps={{
                            className: classes.select,
                            SelectProps: {
                                IconComponent: CustomIcon,
                            }
                        }}
                    />
                </Grid>
                <Grid item md={4}>

                    <KenSelect
                        options={options}
                        handleChange={(e) => {

                        }}
                        inputProps={{
                            className: classes.selectText,
                        }}
                        textFieldProps={{
                            className: classes.select,
                            SelectProps: {
                                IconComponent: CustomIcon,
                            }
                        }}
                    />
                </Grid>
                <Grid item md={3.5}>

                <KenTextField
                        
                        placeholder={"Search..."}
                        handleChange={(e) => {

                        }}
                        inputProps={{
                            className: classes.selectText,
                        }}
                        textFieldProps={{
                            className: classes.select,
                            SelectProps: {
                                IconComponent: CustomIcon,
                            }
                        }}
                    />
                </Grid>
            
                <Grid item md={0.5}>

                {!isMobileScreen ? <Grid item md={1}>
                      <FilterAltOffIcon className={classes.filterIcon}/>
                  </Grid>: undefined}
                </Grid>
                </Grid>
                <br />
            <hr className={classes.Dline} />
            <br /> <br />
            
           <div className={classes.list}>
            <div>
            <KenGrid 
                
                columns={columns}
                data={data}
                pagination={{ disabled: false }}
                toolbarDisabled
            />
            </div>
            </div>
            
           
            
            
      
            
            
            
    </React.Fragment>
  )
}
